import request from '@/utils/request'
const userApi = {
  add: '/pay/merchant/create',
  del: '/pay/merchant/delete',
  edit: '/pay/merchant/update',
  editStatus: '/pay/merchant/update-status',  // 修改 商户状态 开启或关闭
  detail: '/pay/merchant/get',
  list: '/pay/merchant/page',
  listbyIds: '/pay/merchant/list'
}
// 增
export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}
// 删
export function del (parameter) {
  return request({
    url: userApi.del,
    method: 'get',
    params: parameter
  })
}
// 改
export function edit (data) {
  return request({
    url: userApi.edit,
    method: 'post',
    data: data
  })
}
// 改 状态
export function editStatus (data) {
  return request({
    url: userApi.editStatus,
    method: 'post',
    data: data
  })
}
// 查 详情
export function detail (parameter) {
  return request({
    url: userApi.detail,
    method: 'get',
    params: parameter
  })
}
// 查 列表
export function list (parameter) {
  return request({
    url: userApi.list,
    method: 'get',
    params: parameter
  })
}
// 查 列表 by Ids
export function listbyIds (parameter) {
  return request({
    url: userApi.listbyIds,
    method: 'get',
    params: parameter
  })
}
